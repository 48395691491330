export default function calculateAverageReview(reviews: any[]): number {
  if (reviews.length) {
    let sumReview = 0;
    reviews.forEach((review) => {
      sumReview = sumReview + review.review_points;
    });
    const averageReview = sumReview / reviews.length;
    return Math.round(averageReview);
  }
  return 0;
}
