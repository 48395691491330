import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { SnakbarService } from 'src/app/shared/Service/snakBar.service';
import { HomeService } from './service/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private homeService: HomeService,
    private snackbarService: SnakbarService,
    private loader: LoaderService
  ) {}
  images = ['', 2, 5].map((n) => `/assets/Images/About Us/about${n}.jpg`);
  editCombo = [];
  editedCombo;
  data;
  bestSellerProducts = [];
  products = [];
  comboProducts = [];
  testimonial = [{
    review_comments: "Arthodack Oil: After years of battling arthritis pain and trying countless remedies, I finally found relief with Rajguru’s Arthodack Oil. It’s the only natural solution that truly works, and I can’t believe the difference it’s made in my daily life!",
    review_points: 5,
    user_id: {
      first_name: 'Suresh',
      last_name: '62',
      user_image:
        '/assets/Images/User/user.png',
    },
  },
    {
      review_comments: "Onion Oil for Hair Growth: My hair was thinning, and I tried many products with little success. Rajguru's Onion Oil has been a game-changer for me. My hair feels thicker and healthier… It’s best! ",
      review_points: 4,
      user_id: {
        first_name: 'Priya',
        last_name: '28',
        user_image:
          '/assets/Images/User/user.png',
      },
    },
    {
      review_comments: "Happy Time Energizer: I was searching for something natural to boost my energy and improve intimacy, and Rajguru's Happy Time Energizer has truly exceeded my expectations. It’s not only safe and effective but has also brought a noticeable change in my life. I couldn’t be happier!",
      review_points: 5,
      user_id: {
        first_name: 'Rohan',
        last_name: '34',
        user_image:
          '/assets/Images/User/user.png',
      },
    },
  ];
  ngOnInit(): void {
    this.loader.openDialog();
    this.homeService.getBestsellerProducts().subscribe(
      (products) => {
        this.bestSellerProducts = products.data;
      },
      (err) => {
        this.bestSellerProducts = [];
        this.loader.closeDialog();
        this.snackbarService.showSnackBar('', 'danger');
      }
    );
    this.homeService.getProducts().subscribe(
      (products) => {
        this.products = products.data;
      },
      (err) => {
        this.products = [];
        this.loader.closeDialog();
        this.snackbarService.showSnackBar('', 'danger');
      }
    );
    this.homeService.getComboProducts().subscribe(
      (comboProduct) => {
        this.comboProducts = comboProduct.data;
        this.loader.closeDialog();
      },
      (err) => {
        this.comboProducts = [];
        this.loader.closeDialog();
        this.snackbarService.showSnackBar('', 'danger');
      }
    );
  }
}
