<mat-toolbar
  style="background-color: #0C5503; position: fixed; z-index: 1000"
>
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
    (click)="onToggleSidenav()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <span><a routerLink="/home" class="merriweather-font Rajguru-logo-img-wrapper"><img src="/assets/Images/Logo/Rajguru.png"/></a></span>
  <span class="example-spacer"></span>
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with share icon"
    routerLink="/home/cart"
    routerLinkActive="active"
  >
    <mat-icon>shopping_cart</mat-icon>
  </button>
</mat-toolbar>
