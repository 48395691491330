
<div class="outer-div">
  <div class="inner-div">
    <div style="width: 90%">
      <mat-card>
        <mat-card-title> <h1>About Us</h1></mat-card-title>
        <mat-card-content [innerHTML]="aboutUsData?.header"></mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
