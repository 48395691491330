<div class="main_div">
  <mat-card class="user_deatils_div">
    <section style="display: flex; justify-content: space-between; width: 100%">
      <section>
        <span style="font-size: 20px">User Deatils</span>
      </section>
      <section style="width: 50%; display: flex; justify-content: flex-end">
        <span
          ><mat-icon
            color="primary"
            style="margin-right: 2%; cursor: pointer"
            (click)="editaddress()"
            >create</mat-icon
          ></span
        >&nbsp;&nbsp;&nbsp;
        <!--   <span
          ><mat-icon
            color="warn"
            style="margin-right: 2%; cursor: pointer"
            (click)="deleteAddress()"
            >delete</mat-icon
          ></span
        > -->
      </section>
    </section>
    <section>
      Name : {{ useraddress.first_name | titlecase }}
      {{ useraddress.last_name | titlecase }}
    </section>
    <section>Phone Number: {{ useraddress.mobile_number }}</section>
    <section style="margin-top: 1%">
      <span style="font-size: 20px">User Address</span>
    </section>
    <section>House Number: {{ useraddress.house_number | titlecase }}</section>
    <section>
      street/Colony Name:
      {{ useraddress.street_colony_name | titlecase }}
    </section>
    <section>Landmark: {{ useraddress.landmark | titlecase }}</section>
    <section>
      City:
      {{ useraddress.city | titlecase }}
    </section>
    <section>Pincode: {{ useraddress.pincode | titlecase }}</section>
    <section>State: {{ useraddress.state | titlecase }}</section>
  </mat-card>
</div>
