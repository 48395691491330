<div class="outer-div">
  <div class="new_address_div">
    <section class="button_section">
      <button
        mat-raised-button
        color="primary"
        class="center"
        (click)="addAddress()"
      >
        <mat-icon aria-hidden="false">add</mat-icon>
        add address
      </button>
    </section>
  </div>
  <div class="user-address-div" *ngIf="userAddress.length > 0">
    <div
      *ngFor="let address of userAddress; let i = index"
      style="width: 80%; margin-bottom: 2%"
    >
      <app-address-card
        [useraddress]="address"
        [id]="address._id"
        (refreshData)="getAddress()"
      ></app-address-card>
    </div>
  </div>
  <div class="user-address-div" *ngIf="userAddress.length == 0">
    <img
      style="width: 100%; max-height: 100vh"
      src="\assets\Images\cart\delivery.png"
    />
  </div>
</div>
