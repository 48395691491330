<h3 style="color: red" mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
  <p>{{ data.description }}</p>
  <p *ngIf="data?.subDescription"><b>{{data?.subDescription}}</b></p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onNoClick()">
    Cancel
  </button>
  <button
    mat-raised-button
    color="warn"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    {{ data?.actionButtonName ? data?.actionButtonName : "Delete" }}
  </button>
</div>
