import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PolicyService } from './service/policy.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent implements OnInit {
  policyHeading = '';
  policyKey = '';
  privacyData;
  constructor(
    private activatedRouter: ActivatedRoute,
    private privacySerivce: PolicyService
  ) {}

  ngOnInit(): void {
    this.activatedRouter.params.subscribe((res) => {
      if (res.type === 'TermsAndConditions') {
        this.policyHeading = 'Terms And Conditions';
        this.policyKey = 'termsAndCondition';
        this.privacySerivce.getTermsAndConditions().subscribe(
          (res) => {
            this.privacyData = res.data[0];
          },
          (err) => {
            console.log(err);
          }
        );
      } else if (res.type === 'Privacy') {
        this.policyHeading = 'Privacy Policy';
        this.policyKey = 'privacyPolicy';
        this.privacySerivce.getPrivacyPolicy().subscribe(
          (res) => {
            this.privacyData = res.data[0];
          },
          (err) => {
            console.log(err);
          }
        );
      } else if (res.type === 'Refund') {
        this.policyHeading = 'Refund Policy';
        this.policyKey = 'refund';
        this.privacySerivce.getRefund().subscribe(
          (res) => {
            this.privacyData = res.data[0];
          },
          (err) => {
            console.log(err);
          }
        );
      } else if (res.type === 'Shipping') {
        this.policyHeading = 'Shipping Policy';
        this.policyKey = 'shipping';
        this.privacySerivce.getShipping().subscribe(
          (res) => {
            this.privacyData = res.data[0];
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }
}
