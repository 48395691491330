<div class="outer-div">
  <div class="inner-div" *ngIf="cartData.length > 0">
    <section class="item-section">
      <section class="heading">
        <h2>My Cart ({{ cartData.length }} Items)</h2>
        <h2>
          Total :
          <span>
            <span class="price">₹ {{ AmountDetails.TotalPrice }}</span>
          </span>
        </h2>
      </section>
      <div class="items">
        <mat-card
          style="margin-bottom: 3%"
          *ngFor="let item of cartData; let index = index"
        >
          <mat-card-content *ngIf="item.product_type === 'combo-product'">
            <div class="item-detail">
              <section
                class="image-section"
                (click)="navigateToDeatils(item.combo_product_id._id, 'combo')"
              >
                <img
                  (error)="updateImageCombo($event, index)"
                  [src]="item.combo_product_id.products_images[0]"
                />
              </section>

              <section class="text-section">
                <h2>{{ item.combo_product_id.product_name }}</h2>
                <h3>{{ item.combo_product_id.tag_line }}</h3>
                <h3>{{ item.quantity }}</h3>
                <span>Quantity : </span>
                <section *ngIf="item.addToCart" class="cart-section">
                  <section
                    (click)="removeComboQuantity(index)"
                    class="remove-section"
                  >
                    <mat-icon>remove</mat-icon>
                  </section>
                  <section class="quantity-section">
                    <span style="padding: 2%">{{ item.quantity }}</span>
                  </section>
                  <section (click)="addComboToCart(index)" class="add-section">
                    <mat-icon>add</mat-icon>
                  </section>
                </section>
              </section>

              <section class="amount-section">
                <section>
                  <mat-icon
                    (click)="deleteProduct(index, item._id)"
                    style="cursor: pointer"
                    color="warn"
                    >delete</mat-icon
                  >
                </section>

                <span>
                  <span class="price">₹ {{ item.combo_product_id.price }}</span>
                  &nbsp;
                  <span class="fake_price"
                    >₹ {{ item.combo_product_id.fake_price }}</span
                  ></span
                >
              </section>
            </div>
          </mat-card-content>
          <mat-card-content *ngIf="item.product_type === 'product'">
            <div class="item-detail">
              <section
                class="image-section"
                (click)="navigateToDeatils(item.product_id._id, 'single')"
              >
                <img
                  (error)="updateImageProduct($event, index)"
                  [src]="item.product_id.product_images[0]"
                />
              </section>
              <section class="text-section">
                <h2>{{ item.product_id.product_name }}</h2>
                <h3>{{ item.product_id.tag_line }}</h3>
                <h3>
                  <b> Product Type: </b>
                  {{ item.product_id.product_type }}
                </h3>
                <p>Quantity</p>
                <section *ngIf="item.addToCart" class="cart-section">
                  <section
                    (click)="removeProductQuantity(index)"
                    class="remove-section"
                  >
                    <mat-icon>remove</mat-icon>
                  </section>
                  <section class="quantity-section">
                    <span style="padding: 2%">{{ item.quantity }}</span>
                  </section>
                  <section
                    (click)="addProductToCart(index)"
                    class="add-section"
                  >
                    <mat-icon>add</mat-icon>
                  </section>
                </section>
              </section>
              <section class="amount-section">
                <section>
                  <mat-icon
                    (click)="deleteProduct(index, item._id)"
                    style="cursor: pointer"
                    color="warn"
                    >delete</mat-icon
                  >
                </section>
                <span>
                  <span class="price">₹ {{ item.product_id.price }}</span>
                  &nbsp;
                  <span class="fake_price"
                    >₹ {{ item.product_id.fake_price }}</span
                  ></span
                >
              </section>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </section>
    <section class="total-section">
      <mat-card>
        <mat-card-title>Price Detail</mat-card-title>
        <mat-card-content>
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <section
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <h3>Total MRP</h3>
              <h3>₹ {{ AmountDetails.TotalMRP }}</h3>
            </section>
            <section
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <h3>Discount On MRP</h3>
              <h3>₹ {{ AmountDetails.TotalMRP - AmountDetails.TotalPrice }}</h3>
            </section>
            <hr />
            <section
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <h3 style="font-weight: bold">Total Amount</h3>
              <h3 style="font-weight: bold">
                ₹ {{ AmountDetails.TotalPrice }}
              </h3>
            </section>
          </div>
          <hr />
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            (click)="navigateToCheckout()"
          >
            Place Order
          </button>
        </mat-card-actions>
      </mat-card>
    </section>
  </div>
  <div class="inner-div" *ngIf="cartData.length == 0">
    <img
      style="width: 100%; max-height: 100vh"
      src="\assets\Images\cart\Empty-Cart.png"
    />
  </div>
</div>
