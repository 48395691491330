<div class="outer-div">
  <div class="inner-div">
    <div style="width: 90%">
      <mat-card>
        <mat-card-title>
          <h1 *ngIf="policyHeading">
            {{ policyHeading }}
          </h1></mat-card-title
        >
        <mat-card-content
          *ngIf="privacyData && policyKey"
          [innerHTML]="privacyData[policyKey]"
        ></mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
