<div class="outer-div">
  <div class="inner-div">
    <!--    Form Section starts  -->
    <div class="form-section">
      <div class="inner-form-div">
        <section><h3>Change Password</h3></section>
        <section style="margin-top: 5%">
          <!--    Signup Form starts  -->

          <form
            [formGroup]="passwordChangeForm"
            (ngSubmit)="onchangePassword()"
          >
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Password</mat-label>
              <input
                [type]="passwordType"
                matInput
                formControlName="password"
                placeholder="Enter Password"
              />
              <mat-icon
                matSuffix
                (click)="showPassword()"
                *ngIf="passwordType === 'password'"
              >
                lock
              </mat-icon>
              <mat-icon
                matSuffix
                (click)="showPassword()"
                *ngIf="passwordType === 'text'"
              >
                lock_open
              </mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Confirm Password</mat-label>
              <input
                (keyup)="onChangesValue()"
                [type]="confirmPasswordType"
                matInput
                formControlName="confirm_password"
                placeholder="Enter Password"
              />
              <mat-icon
                matSuffix
                (click)="showConfirmPassword()"
                *ngIf="confirmPasswordType === 'password'"
              >
                lock
              </mat-icon>
              <mat-icon
                matSuffix
                (click)="showConfirmPassword()"
                *ngIf="confirmPasswordType === 'text'"
              >
                lock_open
              </mat-icon>
              <mat-hint
                *ngIf="
                  passwordChangeForm.controls['confirm_password']?.errors
                    ?.notMatch
                "
                >Password Does Not match
              </mat-hint>
            </mat-form-field>
            <section>
              <button
                [disabled]="passwordChangeForm.invalid"
                color="primary"
                type="submit"
                mat-raised-button
              >
                Continue
              </button>
            </section>
          </form>
          <!--    Signup Form ends  -->
        </section>
      </div>
    </div>
  </div>
</div>
