import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hero-product',
  templateUrl: './hero-product.component.html',
  styleUrls: ['./hero-product.component.scss'],
})
export class HeroProductComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToDetails() {
    this.router.navigate([
      '/home/product/product-detail/single/66d1b9a91094d4000375546e',
    ]);
  }
}
