<div class="outer-div-product-slider" style="padding: 2% !important">
  <div class="inner-div" *ngFor="let product of products; let i = index">
    <mat-card>
      <app-product-card-slider
        [images]="product.products_images"
        [isBestSeller]="product?.best_seller"
      ></app-product-card-slider>
      <br />
      <div class="padding">
        <mat-card-title
          class="product-font"
          (click)="navigateToDeatils(product._id)"
          >{{ product.product_name | titlecase }}</mat-card-title
        >
        <mat-card-subtitle (click)="navigateToDeatils(product._id)"
          ><span class="product-font">{{ product.tag_line }}</span>
          <p class="product-font">
            <b>Products : </b>
            <span
              *ngFor="let type of product.products; let index = index"
              value="type"
            >
              {{ type?.product_name | titlecase }} ({{
                type?.product_type | titlecase
              }}) <span *ngIf="index + 1 != product.products.length">|</span>
            </span>
          </p></mat-card-subtitle
        >

        <mat-card-content>
          <div class="reviews">
            <i
              class="fas fa-star"
              *ngFor="
                let star of [].constructor(
                  averageReviewCalculator(product?.reviews)
                )
              "
            ></i>
            <i
              class="far fa-star"
              *ngFor="
                let star of [].constructor(
                  5 - averageReviewCalculator(product?.reviews)
                )
              "
            ></i>
          </div>
          <p>
            <span class="price">₹ {{ product.price }}</span
            >&nbsp;
            <span class="fake_price">₹ {{ product.fake_price }}</span>
          </p>
        </mat-card-content>
        <mat-card-actions>
          <div class="btn-section">
            <button
              color="primary"
              mat-raised-button
              *ngIf="!product.addToCart"
              (click)="addToCart(i)"
            >
              Add To Cart &nbsp; <mat-icon>shopping_cart</mat-icon>
            </button>
            <section class="cart-section" *ngIf="product.addToCart">
              <section class="remove-section" (click)="removeQuantity(i)">
                <mat-icon>remove</mat-icon>
              </section>
              <section class="quantity-section">
                <span style="padding: 2%">{{ product.quantity }}</span>
              </section>
              <section class="add-section" (click)="addToCart(i)">
                <mat-icon>add</mat-icon>
              </section>
            </section>
          </div>
        </mat-card-actions>
      </div>
    </mat-card>
  </div>
</div>
