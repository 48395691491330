<div class="outer-div">
  <div class="inner-div">
    <!--    Form Section starts  -->
    <div class="form-section">
      <div class="inner-form-div">
        <section class="logo-section">
          <img src="\assets\Images\Logo\rajguru-sidebar.png" />
        </section>
        <section><h1>Welcome To Rajguru</h1></section>
        <section><h3>Register User</h3></section>
        <section>
          <!--    Signup Form starts  -->
          <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>First Name</mat-label>
              <input
                type="text"
                matInput
                formControlName="first_name"
                placeholder="Email"
              />
              <mat-icon color="primary" matSuffix>person</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" color="accent">
              <mat-label>Last Name</mat-label>
              <input
                type="text"
                matInput
                formControlName="last_name"
                placeholder="Last Name"
              />
              <mat-icon color="primary" matSuffix>person</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" color="accent">
              <mat-label>Phone Number</mat-label>
              <input
                type="text"
                matInput
                minlength="10"
                maxlength="15"
                formControlName="phone_number"
                placeholder="Enter Phone Number"
              />
              <mat-icon color="primary" matSuffix>phone</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" color="accent">
              <mat-label>Email</mat-label>
              <input
                type="email"
                matInput
                formControlName="email"
                placeholder="Email"
              />
              <mat-icon color="primary" matSuffix>email</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" color="accent">
              <mat-label>Password</mat-label>
              <input
                matInput
                formControlName="password"
                placeholder="Password"
                type="password"
              />
              <mat-icon color="primary" matSuffix>lock</mat-icon>
            </mat-form-field>
            <section>
              <p>
                Already have an account ?
                <a routerLink="/login">Login Now</a>
              </p>
            </section>
            <section>
              <button
                [disabled]="signUpForm.invalid"
                color="primary"
                type="submit"
                mat-raised-button
              >
                Continue
              </button>
            </section>
          </form>
          <!--    Signup Form ends  -->
        </section>
      </div>
    </div>
  </div>
</div>
