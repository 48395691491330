<mat-nav-list role="list" style="padding: 0 !important">
  <div
    style="
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    "
    routerLink="/home"
  >
    <img style="width: 100%" src="\assets\Images\Logo\rajguru-sidebar.png" />
  </div>

  <!--  <mat-list-item
    style="background-color: rgb(30, 54, 28)"
    role="listitem"
    routerLink="/home"
    [routerLinkActive]="'is-active'"
    ><a style="color: white" (click)="sidenavclose()">
      <mat-icon style="color: white">account_circle</mat-icon>
      <span class="nav-caption">Users</span>
    </a></mat-list-item
  > -->

  <mat-list-item
    role="listitem"
    routerLink="/home"
    [routerLinkActive]="'is-active'"
    [routerLinkActiveOptions]="{ exact: true }"
    (click)="sidenavclose()"
    ><a>
      <mat-icon color="dark">home</mat-icon>
      <span class="nav-caption merriweather-font">Home</span>
    </a></mat-list-item
  >
  <mat-list-item
    role="listitem"
    routerLink="/home/about-us"
    (click)="sidenavclose()"
    [routerLinkActive]="'is-active'"
    ><a>
      <mat-icon color="dark">groups</mat-icon>
      <span class="nav-caption merriweather-font">About Us</span>
    </a></mat-list-item
  >

  <mat-list-item role="listitem" (click)="showItems = !showItems"
    ><a>
      <mat-icon *ngIf="showItems" color="dark">expand_less</mat-icon>
      <mat-icon *ngIf="!showItems" color="dark">expand_more</mat-icon>
      <span class="nav-caption merriweather-font">Shop By Type</span>
    </a></mat-list-item
  >
  <div *ngIf="showItems">
    <ul class="navigationbar" style="margin-left: 15%">
      <li (click)="sidenavclose()" class="subItem merriweather-font">
        <a routerLink="/home/product/single" [routerLinkActive]="'is-active'"
          ><mat-icon>arrow_right</mat-icon>Single Product</a
        >
      </li>
      <li (click)="sidenavclose()" class="subItem">
        <a routerLink="/home/product/combo" [routerLinkActive]="'is-active'"
          ><mat-icon>arrow_right</mat-icon>Combo Product</a
        >
      </li>
    </ul>
  </div>
  <mat-list-item
    role="listitem"
    routerLink="/home/order"
    [routerLinkActive]="'is-active'"
    (click)="sidenavclose()"
    ><a
      ><mat-icon color="dark">shopping_bag</mat-icon>
      <span class="nav-caption merriweather-font">Orders</span></a
    ></mat-list-item
  >
  <mat-list-item
    *ngIf="userData"
    (click)="sidenavclose()"
    role="listitem"
    routerLink="/home/account"
    [routerLinkActive]="'is-active'"
    ><a>
      <mat-icon color="dark">account_circle</mat-icon>
      <span class="nav-caption merriweather-font">Account Settings</span>
    </a></mat-list-item
  >
  <mat-list-item
    (click)="sidenavclose()"
    role="listitem"
    routerLink="/home/contact"
    [routerLinkActive]="'is-active'"
    ><a>
      <mat-icon color="dark">call</mat-icon>
      <span class="nav-caption merriweather-font">Contact Us</span>
    </a></mat-list-item
  >

  <mat-list-item
    role="listitem"
    routerLink="/policy/TermsAndConditions"
    [routerLinkActive]="'is-active'"
    (click)="sidenavclose()"
    ><a
      ><mat-icon color="dark">list_alt</mat-icon>
      <span class="nav-caption merriweather-font">Terms & Conditions</span></a
    ></mat-list-item
  >
  <mat-list-item
    role="listitem"
    routerLink="/policy/Refund"
    [routerLinkActive]="'is-active'"
    (click)="sidenavclose()"
    ><a
      ><mat-icon color="dark">payments</mat-icon>
      <span class="nav-caption merriweather-font">Refund Policy</span></a
    ></mat-list-item
  >
  <mat-list-item
    role="listitem"
    routerLink="/policy/Shipping"
    [routerLinkActive]="'is-active'"
    (click)="sidenavclose()"
    ><a
      ><mat-icon color="dark">local_shipping</mat-icon>
      <span class="nav-caption merriweather-font">Shipping Policy</span></a
    ></mat-list-item
  >
  <mat-list-item
    role="listitem"
    routerLink="/policy/Privacy"
    [routerLinkActive]="'is-active'"
    (click)="sidenavclose()"
    ><a
      ><mat-icon color="dark">list_alt</mat-icon>
      <span class="nav-caption merriweather-font">Privacy Policy</span></a
    ></mat-list-item
  >
  <hr />
  <mat-list-item (click)="logout()" *ngIf="userData" role="listitem"
    ><a>
      <mat-icon color="dark">logout</mat-icon>
      <span class="nav-caption merriweather-font">Logout</span>
    </a></mat-list-item
  >

  <mat-list-item
    (click)="sidenavclose()"
    routerLink="/login"
    *ngIf="!userData"
    role="listitem"
    ><a>
      <mat-icon color="dark">login</mat-icon>
      <span class="nav-caption merriweather-font">Login</span>
    </a></mat-list-item
  >
</mat-nav-list>
