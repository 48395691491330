<div class="hero-product-outer-wrapper" (click)="navigateToDetails()">
  <div class="image-wrapper">
    <img src="assets\Images\Hero\hero-product.png" />
  </div>
  <div class="content-wrapper">
    <div class="heading-wrapper">The All-Natural Way to Happier Times</div>
    <div class="sub-heading-wrapper">Energizer for Both Men & Women</div>
    <div class="points-wrapper">
      <div class="points-first-wrapper">
        <h4><mat-icon>check_circle</mat-icon> Boost your energy</h4>
        <h4><mat-icon>check_circle</mat-icon> Deepen your connection</h4>
        <h4><mat-icon>check_circle</mat-icon> Perfect for both of you</h4>
      </div>
      <span class="points-second-wrapper">
        <h4><mat-icon> check_circle</mat-icon> No harmful side effects</h4>
        <h4><mat-icon> check_circle</mat-icon> 100% safe & natural</h4>
      </span>
    </div>
  </div>
</div>
