<div class="outer-div">
  <div class="banner-class">
    <app-banner class="banner-component" [images]="images"></app-banner>
  </div>
  <div class="hr_div_best_seller">
    <hr />
  </div>
  <div class="product-section" *ngIf="bestSellerProducts.length > 0">
    <div class="heading">
      <section>
        <h2 class="haeding-products">
          <mat-icon color="primary" style="margin-right: 10px">spa</mat-icon
          >Best Seller
        </h2>
      </section>
      <!-- <section>
        <h3>
          <a routerLink="/home/product/single" style="color: gray">See all</a>
        </h3>
      </section> -->
    </div>
    <div class="product-slider">
      <app-product-slider [products]="bestSellerProducts"></app-product-slider>
    </div>
  </div>

  <div class="hr_div" *ngIf="bestSellerProducts.length > 0">
    <hr />
  </div>
  <div class="hero-section-wrapper">
    <app-hero-product class="hero-section"></app-hero-product>
  </div>
  <div class="hr_div" *ngIf="bestSellerProducts.length > 0">
    <hr />
  </div>
  <div class="product-section" *ngIf="products.length > 0">
    <div class="heading">
      <section>
        <h2 class="haeding-products">
          <mat-icon color="primary" style="margin-right: 10px">spa</mat-icon
          >Products
        </h2>
      </section>
      <section>
        <h3>
          <a routerLink="/home/product/single" style="color: gray">See all</a>
        </h3>
      </section>
    </div>
    <div class="product-slider">
      <app-product-slider [products]="products"></app-product-slider>
    </div>
  </div>
  <div class="hr_div">
    <hr />
  </div>
  <div class="product-section" *ngIf="comboProducts.length > 0">
    <div class="heading">
      <section>
        <h2 class="haeding-products">
          <mat-icon color="primary" style="margin-right: 10px">spa</mat-icon
          >Combo Products
        </h2>
      </section>
      <section>
        <h3>
          <a routerLink="/home/product/combo" style="color: gray">See all</a>
        </h3>
      </section>
    </div>

    <div class="product-slider">
      <app-combo-product-slider
        [products]="comboProducts"
      ></app-combo-product-slider>
    </div>
  </div>
  <div class="hr_div">
    <hr />
  </div>
  <div class="product-section" *ngIf="comboProducts.length > 0">
    <div class="heading">
      <section>
        <h2 class="haeding-products">
          <mat-icon color="primary" style="margin-right: 10px">spa</mat-icon
          >Testimonial
        </h2>
      </section>
    </div>
  </div>
  <div class="review-section">
    <app-reviews [isTestimonial]="true" [reviews]="testimonial"></app-reviews>
  </div>
</div>
