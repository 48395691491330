<div class="main-div" *ngIf="userData">
  <mat-card style="width: 100%">
    <div class="data-section">
      <div class="image-section">
        <div class="inner-image">
          <mat-progress-bar
            mode="buffer"
            *ngIf="imageLoader"
          ></mat-progress-bar>
          <div class="image-container" *ngIf="!imageLoader">
            <img
              style="
                width: 150px;
                border-radius: 50%;
                height: 150px;
                overflow: hidden;
              "
              class="no-shadow no-border"
              [src]="
                userData?.user_image
                  ? userData?.user_image
                  : 'https://expertphotography.b-cdn.net/wp-content/uploads/2018/10/cool-profile-pictures-retouching-1.jpg'
              "
            />
            <input
              type="file"
              hidden
              #image
              accept="image/png, image/gif, image/jpeg"
              placeholder="Select Image"
              required
              (change)="ImageSelected($event)"
            />

            <button
              type="button"
              class="top-right"
              (click)="image.click()"
              style="background: transparent; border: none"
            >
              <mat-icon color="primary" (click)="editInformation()"
                >edit</mat-icon
              >
            </button>
          </div>
        </div>
      </div>
      <div class="text-section">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input
            type="text"
            matInput
            required
            placeholder="First Name"
            (change)="saveChanges('first_name')"
            [formControl]="first_name"
          />
          <mat-icon color="primary" matSuffix>edit</mat-icon></mat-form-field
        >
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input
            type="text"
            matInput
            required
            placeholder="Last Name"
            (change)="saveChanges('last_name')"
            [formControl]="last_name"
          />
          <mat-icon color="primary" matSuffix>edit</mat-icon></mat-form-field
        >
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            required
            placeholder="Email"
            (change)="saveChanges('email')"
            [formControl]="email"
          />
          <mat-icon color="primary" matSuffix>edit</mat-icon></mat-form-field
        >
        <mat-form-field appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input
            type="text"
            matInput
            required
            placeholder="Phone Number"
            (change)="saveChanges('phone_number')"
            [formControl]="phone_number"
          />
          <mat-icon color="primary" matSuffix>edit</mat-icon></mat-form-field
        >

        <h3 *ngIf="userData.number_of_times_order_placed">
          <span style="display: flex; align-items: center">
            <mat-icon color="primary">shopping_bag</mat-icon
            ><b>Total Orders Placed :</b>
          </span>
          {{ userData.number_of_times_order_placed }}
        </h3>
      </div>
    </div>
  </mat-card>
</div>
