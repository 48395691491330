<div class="outer-div">
  <div class="header">
    <img src="../../../assets/Images/Contact Us/contact.jpg" />
    <div class="centered">
      <h1>Contact Us</h1>
      <p>
        We're here to help and answer any question you might have . We look
        forward to hearing from you .
      </p>
    </div>
  </div>
  <div class="breadcrumb">
    <h2 class="center">
      <a routerLink="/home">Home</a>
      <mat-icon>chevron_right</mat-icon>
      <a routerLink="/contact" routerLinkActive="active"> Contact Us</a>
    </h2>
  </div>
  <div class="information-section">
    <section class="office">
      <h1>Central Office</h1>
      <!-- <p>
        Nam eu mi eget velit vulputate tempor gravida quis massa. In malesuada
        condimentum ultrices. Sed et mauris a purus fermentum elementum. Sed
        tristique semper enim, et gravida orci iaculis et. Nulla facilisi.
      </p> -->
      <h3>© Aarniecheme Enterprises</h3>
      <a href="tel:91 7470679333"
        ><h3><mat-icon color="primary">phone</mat-icon> +91 74706 79333</h3></a
      >
      <h3>
        <mat-icon color="primary">place</mat-icon> SH 48, Dhacha Bhawan, Agar
        Road, Ujjain, Madhya Pradesh, 456010
      </h3>
      <section class="social-section">
        <a
          target="_blank" href="https://www.instagram.com/rajguruherbal/?igsh=MjMzbGJnZTZwcWtx"
        >
          <img class="social-icons" src="../../../assets/Images/Icons/insta.png" />
        </a>
        <!-- <button mat-fab color="primary">
          <mat-icon>twitter</mat-icon>
        </button> -->
        <a target="_blank" href="mailto:Rajguru1981@gmail.com"
          > <img class="social-icons" src="../../../assets/Images/Icons/email.png" />
          </a
        >
        <!--    <button mat-fab color="primary">
          <mat-icon>instagram</mat-icon>
        </button> -->
      </section>
    </section>
     <!-- <section class="opeaning-hours">
     <h1 style="text-align: center">Opening Hours</h1>
      <section>
        <h4><b>Monday</b>: 9:00 – 19:00</h4>
        <h4><b>Tuesday</b>: 9:00 – 19:00</h4>
        <h4><b>Wednesday</b>: 9:00 – 19:00</h4>
        <h4><b>Thursday</b>: 9:00 – 19:00</h4>
        <h4><b>Monday</b>: 9:00 – 19:00</h4>
        <h4><b>Tuesday</b>: 9:00 – 19:00</h4>
        <h4><b>Wednesday</b>: 9:00 – 19:00</h4>
      </section> 
    </section>-->
    <section class="form">
      <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
        <h1 style="text-align: center">Get In Touch</h1>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>First Name</mat-label>
          <input
            type="text"
            matInput
            formControlName="first_name"
            placeholder="Enter First Name"
          />
          <mat-icon color="primary" matSuffix>person</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Last Name</mat-label>
          <input
            type="text"
            matInput
            formControlName="last_name"
            placeholder="Enter Last Name"
          />
          <mat-icon color="primary" matSuffix>person</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            formControlName="email"
            placeholder="Email"
          />
          <mat-icon color="primary" matSuffix>email</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Phone number</mat-label>
          <input
            type="text"
            matInput
            formControlName="phone_number"
            placeholder="Phone number"
          />
          <mat-icon color="primary" matSuffix>call</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent">
          <mat-label>Message</mat-label>
          <textarea
            matInput
            formControlName="query"
            placeholder="Your Message"
            type="text"
          ></textarea>
        </mat-form-field>
        <section style="justify-content: center; width: 100%; display: flex">
          <button
            [disabled]="contactUsForm.invalid"
            color="primary"
            type="submit"
            mat-raised-button
          >
            Continue
          </button>
        </section>
      </form>
    </section>
  </div>
</div>
