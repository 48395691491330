<ngb-carousel *ngIf="bannerData">
  <ng-template ngbSlide *ngFor="let banner of bannerData">
    <div class="picsum-img-wrapper">
      <img [src]="banner.banner_image" alt="Random first slide" />
    </div>
    <div class="carousel-caption">
      <h1>{{ banner.banner_title | titlecase }}</h1>
      <p>{{ banner.banner_subtitle | titlecase }}</p>
    </div>
  </ng-template>
</ngb-carousel>
