<div class="img-container"><ngb-carousel *ngIf="images">
  <ng-template ngbSlide *ngFor="let image of images; let index = index">
    <div class="picsum-img-wrapper">
      <img
        (error)="updateUrl($event, index)"
        [src]="image"
        alt="Random first slide"
      />
    </div>
  </ng-template>
</ngb-carousel>
<div *ngIf="isBestSeller" class="bestseller-badge">
  BestSeller
</div>
</div>
