<div class="outer-div">
  <div class="inner-div">
    <section class="item-section">
      <section class="heading">
        <h1>Select Delivery Address</h1>
        <h3>
          <button mat-raised-button color="primary" (click)="addAddress()">
            Add New Address
          </button>
        </h3>
      </section>
      <div style="width: 80%" *ngIf="userAddress.length == 0">
        <img
          style="width: 100%; max-height: 100vh"
          src="\assets\Images\cart\delivery.png"
        />
      </div>
      <section style="width: 80%" *ngIf="userAddress.length > 0">
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="selectedAddress"
        >
          <mat-card
            style="
              margin-bottom: 10px;
              width: 100%;
              display: flex;
              justify-content: space-between;
            "
            *ngFor="let o of userAddress"
          >
            <mat-radio-button
              class="example-radio-button"
              [value]="o._id"
              (change)="changeSelectedUserAddress($event)"
            >
              <h3>
                <b
                  >{{ o.first_name | titlecase }}&nbsp;{{
                    o.last_name | titlecase
                  }}</b
                >
              </h3>
              <br />
              <h4
                style="width: 100%; word-break: break-word; white-space: normal"
              >
                <b>Address </b>: {{ o.house_number }}&nbsp;,&nbsp;{{
                  o.street_colony_name
                }}&nbsp;,&nbsp;{{ o.city }}&nbsp;,&nbsp;{{
                  o.pincode
                }}&nbsp;,&nbsp;{{ o.state }}<br /><b>Mobile</b> :
                {{ o.mobile_number }}
                <br />
                <b>Landmark </b>: {{ o.landmark }}
              </h4>
              <br />
              Delivery Available
            </mat-radio-button>
          </mat-card>
        </mat-radio-group>
      </section>
    </section>
    <section class="total-section" *ngIf="userCart">
      <section class="heading">
        <h3>My Cart ({{ userCart.cartData.length }} Items)</h3>
        <h3>
          Total :
          <span>
            <span class="price">₹ {{ userCart.amountDetail.TotalPrice }}</span>
          </span>
        </h3>
      </section>
      <div class="items">
        <mat-card
          style="margin-bottom: 3%"
          *ngFor="let item of userCart.cartData; let index = index"
        >
          <mat-card-content *ngIf="item.product_type === 'combo-product'">
            <div class="item-detail">
              <section class="image-section">
                <img
                  (error)="updateImageCombo($event, index)"
                  [src]="item.combo_product_id.products_images[0]"
                />
              </section>
              <section class="text-section">
                <h3>{{ item.combo_product_id.product_name }}</h3>
                <h3>Quantity : {{ item.quantity }}</h3>
              </section>
              <section class="amount-section">
                <span class="price">₹ {{ item.combo_product_id.price }}</span>
                &nbsp;
                <span class="fake_price"
                  >₹ {{ item.combo_product_id.fake_price }}</span
                >
              </section>
            </div>
          </mat-card-content>
          <mat-card-content *ngIf="item.product_type === 'product'">
            <div class="item-detail">
              <section class="image-section">
                <img
                  (error)="updateImageProduct($event, index)"
                  [src]="item.product_id.product_images[0]"
                />
              </section>
              <section class="text-section">
                <h3>{{ item.product_id.product_name }}</h3>
                <h3>Quantity : {{ item.quantity }}</h3>
                <h4>Product Type : {{ item.product_id.product_type }}</h4>
              </section>
              <section class="amount-section">
                <span class="price">₹ {{ item.product_id.price }}</span>
                &nbsp;
                <span class="fake_price"
                  >₹ {{ item.product_id.fake_price }}</span
                >
              </section>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <mat-card>
        <mat-card-title>Price Detail</mat-card-title>
        <mat-card-content>
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <section
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <h3>Total MRP</h3>
              <h3>₹ {{ userCart.amountDetail.TotalMRP }}</h3>
            </section>
            <section
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <h3>Discount On MRP</h3>
              <h3>
                ₹
                {{
                  userCart.amountDetail.TotalMRP -
                    userCart.amountDetail.TotalCalculatedPrice
                }}
              </h3>
            </section>
            <section
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <h3>Transaction Fee (non-refundable)</h3>
              <h3>₹ {{ AmountDetails.TransactionFee }}</h3>
            </section>
            <hr />
            <section
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <h3 style="font-weight: bold">Total Amount</h3>
              <h3 style="font-weight: bold">
                ₹ {{ userCart.amountDetail.TotalPrice }}
              </h3>
            </section>
          </div>
          <hr />
        </mat-card-content>
        <div style="width: 100%; text-align: center">
          <p *ngIf="!selectedAddress" style="color: red">
            * Select/Add address to continue
          </p>
        </div>
        <mat-card-actions>
          <button
            [disabled]="!selectedAddress"
            mat-raised-button
            color="primary"
            (click)="clickToPay()"
          >
            Pay
          </button>
        </mat-card-actions>
      </mat-card>
    </section>
  </div>
</div>
