// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'https://raj-guru-backend.vercel.app/',
  firebase: {
    apiKey: "AIzaSyClRWNQzjLId8jalQm338DW-HFLzR11p74",
    authDomain: "raj-guru-admin.firebaseapp.com",
    projectId: "raj-guru-admin",
    storageBucket: "raj-guru-admin.appspot.com",
    messagingSenderId: "108586493016",
    appId: "1:108586493016:web:e848f92fdbc16b4eb9562d",
    measurementId: "G-5JF79WZMHS"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
