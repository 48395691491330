<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <app-side-bar (closesidenav)="sidenav.close()"></app-side-bar>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
    <main><router-outlet></router-outlet></main>
    <footer><app-footer></app-footer></footer>
  </mat-sidenav-content>
</mat-sidenav-container>
