<div class="footer">
  <div class="row">
    <div class="icons">
      <a
        href="https://www.facebook.com/people/Rajguru-Herbal/61565051360690/?mibextid=ZbWKwL"
        target="_blank"
        ><mat-icon>facebook</mat-icon></a
      >
      <a
        target="_blank"
        href="https://www.instagram.com/rajguruherbal/?igsh=MjMzbGJnZTZwcWtx"
      >
        <img
          class="insta-icon-wrapper"
          src="../../../assets/Images/Icons/insta-contact-icon.png"
      /></a>
      <a target="_blank" href="mailto:rajguru1981@gmail.com"
        ><mat-icon>email</mat-icon></a
      >
      <a target="_blank" href="tel:91 9425061638"><mat-icon>phone</mat-icon></a>
    </div>
  </div>

  <div class="row">
    <ul>
      <li><a routerLink="contact">Contact us</a></li>
      <li><a routerLink="about-us">About Us</a></li>
      <li><a routerLink="/policy/Privacy">Privacy Policy</a></li>
      <li><a routerLink="/policy/TermsAndConditions">Terms & Conditions</a></li>
      <li><a routerLink="/policy/Refund">Refund Policy</a></li>
      <li><a routerLink="/policy/Shipping">Shipping Policy</a></li>
    </ul>
  </div>

  <div class="row">Rajguru Copyright © 2024 Rajguru - All rights reserved</div>
  <div class="row">Aarniecheme Enterprises</div>

</div>
